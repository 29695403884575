<template>
  <div class="PersonalInformation">
    <div class="type1" v-if="type == 0 || type == 3">
      <img style="width:120px;height:72" src="@/assets/images/AccountSettings/nameYes.png" alt="" />
      <div class="yell">账号尚未通过实名认证</div>
      <el-button @click="type = 4" type="primary">立即认证</el-button>
      <div class="bottoms">
        个人实名认证信息仅用于本平台内交易和身份识别使用，同时也是为企业进行实名认证以及获取电子签章的前提，认证通过后无法直接取消认证通过状态。如有特殊情况，可通过注销账号的方式同时注销实名认证信息。
      </div>
    </div>
    <div class="type2" v-if="type == 4">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="120px"
        class="demo-ruleForm"
      >
        <el-form-item label="实名手机号" prop="userName">
          <span>{{ ruleForm.userName }}</span>
          <span style="font-size: 12px; color: red">
            ( 实名认证以本号码认证结果为准，过程中请使用本号码认证。)</span
          >
        </el-form-item>
        <el-form-item label="本人姓名" prop="realName">
          <el-input v-model="ruleForm.realName"></el-input>
        </el-form-item>
        <el-form-item label="本人身份证号码" prop="idNum">
          <el-input v-model="ruleForm.idNum"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button @click="resetForm('ruleForm')">返回认证状态</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交认证信息</el-button
          >
        </el-form-item>
      </el-form>
      <div class="bottoms">
        个人实名认证信息仅用于本平台内交易和身份识别使用，同时也是为企业进行实名认证以及获取电子签章的前提，认证通过后无法直接取消认证通过状态。如有特殊情况，可通过注销账号的方式同时注销实名认证信息。
      </div>
    </div>
    <div class="type1" v-if="type == 2">
      <img src="@/assets/images/AccountSettings/nameYesOk.png" style="width:120px;height:72" alt="" />
      <div class="gree">账号已通过实名认证</div>
      <div class="greeItem">
        <span>本人姓名: </span>
        <span>{{ userInfo.realName }}</span>
      </div>
      <div class="greeItem">
        <span>身份证号: </span>
        <span>{{ userInfo.idNum | hiddenIDcard }}</span>
      </div>
      <div class="bottoms">
        个人实名认证信息仅用于本平台内交易和身份识别使用，同时也是为企业进行实名认证以及获取电子签章的前提，认证通过后无法直接取消认证通过状态。如有特殊情况，可通过注销账号的方式同时注销实名认证信息。
      </div>
    </div>
    <div class="type1" v-if="type == 1">
      <div class="yell blue">实名信息申请已提交</div>
      <div class="small">申请资料正在审核中…</div>
    </div>
  </div>
</template>

<script>
import { getRealNameStatus, getPsnAuthUrl } from "@/api/esign";
import { mapState } from "vuex";
export default {
  data() {
    let checkIdCode = (rule, value, callback) => {
      // 信用代码
      if (!value) {
        return callback(new Error("身份证号码不能为空"));
      } else {
        const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的身份证号码"));
        }
      }
    };
    return {
      type: 0,
      ruleForm: {},
      rules: {
        realName: [
          { required: true, message: "请输入本人姓名", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        idNum: [{ required: true, validator: checkIdCode, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.$store.dispatch("getUserInfo");
    this.type = this.userInfo.authenticationStatus;
    this.ruleForm.userName = this.userInfo.userName;
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          getRealNameStatus(this.ruleForm).then(({ code, msg }) => {
            if (code == 200) {
              if (msg == 1) {
                // 如果msg=1就是已经认证过
                this.type = 2;
                this.$store.dispatch("getUserInfo");
              } else if (msg == 0) {
                getPsnAuthUrl(this.ruleForm).then((res) => {
                  if (res.code == 200) {
                    window.open(res.msg);
                  }
                });
              }
            } else if (code == 400) {
              this.$message.error(msg);
            }
          });
        }
      });
    },
    resetForm(formName) {
      this.type = 0;
      this.$refs[formName].resetFields();
    },
    //  this.areaList = this.$store.state.area.areaList;
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  filters: {
    hiddenIDcard(id) {
      if (!id) {
        return "--";
      }
      let reg = /^(.{6})(?:\d+)(.{4})$/;
      return id.replace(reg, "$1******$2"); // 身份证号脱敏，将中间8位替换为“*”
    },
  },
};
</script>

<style lang="less" scoped>
.PersonalInformation {
  margin-top: 20px;
  margin-left: 160px;
  width: 600px;
  .bottoms {
    margin-top: 40px;
    color: rgba(0, 0, 0, 40%);
    font-size: 14px;
    line-height: 24px;
    text-indent: 2em; 
  }
  .type1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    .yell {
      color: #ff7f00;
      line-height: 40px;
      height: 60px;
      font-weight: 600;
      font-size: 16px;
    }
    .gree {
      color: #0DAC65;
      line-height: 40px;
      height: 60px;
      font-weight: 600;
      font-size: 16px;
    }
    .greeItem {
      line-height: 28px;
      font-size: 14px;
      color: rgba(0, 0, 0, 85%);
    }
  }
}
</style>
